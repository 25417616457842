<template>
  <div class="home">
    <header>
    <div class="jumbotron jumbotron-lg jumbotron-fluid mb-0 pb-3 bg-primary position-relative">
        <div class="container-fluid text-white h-100">
            <div class="d-lg-flex align-items-center justify-content-between text-center pl-lg-5">
                <div class="col pt-4 pb-4">
                    <h1>数智化一站式解决方案</h1>
                    <h5 class="font-weight-light" style="margin-top:30px">专业助力软件开发，网站开发，小程序开发</h5>
                    <h5 class="font-weight-light mb-4" style="">服务思维 全新升级</h5>

                    <div>
                    <a href="/case" class="btn btn-lg btn-outline-blue btn-round" style="margin-top:10px;margin-left:10px;margin-right:10px;color:#0d0d0d">案例展示</a>
                    <a href="/contact" class="btn btn-lg btn-outline-white btn-round" style="margin-top:10px;margin-left:10px;margin-right:10px">联系我们</a>
                    </div>
                </div>
                <div style="max-height:280px;position:relative;z-index:1" class="col align-self-bottom align-items-right h-max-380 h-xl-560 position-relative z-index-1">
                    <img src="/images/bg.png" class="rounded img-fluid" style="max-height:430px;">
                </div>
            </div>
        </div>
    </div>
    <svg style="-webkit-transform:rotate(-180deg); -moz-transform:rotate(-180deg); -o-transform:rotate(-180deg); transform:rotate(-180deg); margin-top: -1px;" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 1440 126" xml:space="preserve">
    <path class="bg-primary" d="M685.6,38.8C418.7-11.1,170.2,9.9,0,30v96h1440V30C1252.7,52.2,1010,99.4,685.6,38.8z"></path>
    </svg>
</header>

    <section class="slice slice-lg pt-lg-6 pb-0 pb-lg-6 bg-section-secondary" style="margin-top:50px">
        <div class="container">
            <!-- Title -->
            <!-- Section title -->
            <div class="row mb-5 justify-content-center text-center">
                <div class="col-lg-6">
                    <h2 class=" mt-4">解决方案</h2>
                </div>
            </div>
            <!-- Card -->
            <div class="row mt-5">
                <div class="col-md-4">
                    <div class="card">
                        <div class="card-body pb-5">
                            <div class="pt-4 pb-5">
                                <img src="/images/illustration-5.svg" class="img-fluid img-center" style="height: 150px;" alt="Illustration">
                            </div>
                            <h5 class="h4 lh-130 mb-3">网站开发</h5>
                            <p class="text-muted mb-0">互联网的日益发展使得一个好的网站是一个企业的第一门面，我们助力于帮助您通过建设网站获得源源不断的流量.</p>
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="card">
                        <div class="card-body pb-5">
                            <div class="pt-4 pb-5">
                                <img src="/images/illustration-6.svg" class="img-fluid img-center" style="height: 150px;" alt="Illustration">
                            </div>
                            <h5 class="h4 lh-130 mb-3">APP/小程序开发</h5>
                            <p class="text-muted mb-0">我们日常使用的最多的场景就是APP和小程序了，无论您是餐饮，娱乐，还是政务，出行，我们助力您生成最佳方案.</p>
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="card">
                        <div class="card-body pb-5">
                            <div class="pt-4 pb-5">
                                <img src="/images/illustration-7.svg" class="img-fluid img-center" style="height: 150px;" alt="Illustration">
                            </div>
                            <h5 class="h4 lh-130 mb-3">软件开发</h5>
                            <p class="text-muted mb-0">日常工作中，我们需要很多场景要借助电脑完成工作，我们助力于您获得便捷实用的各种工具，行业，以及管理软件.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <section class="slice slice-lg" style="margin-top:80px;background:linear-gradient(#fff,#eee);padding:50px 0px">
        <div class="container">
            <div class="py-6">
                <div class="row row-grid justify-content-between">
                    <div class="col-lg-8 order-lg-2">
                        <h2 style="text-align: center;margin-top: 20px;">关于我们</h2>
                        <p class="my-4">
                            <ul style="text-align: left;color:#666;font-size:16px">
                                <li>团队成员均毕业于国内TOP级别院校，或来自于各大软件互联网厂商，多数成员具有10年以上的互联网、软件开发经验。</li>
                                <li style="margin-top:10px">成立以来，为响应互联网+、数字化经济、智能制造等新时代号角，为政府、企业提供了丰富的数智化场景解决方案。</li>
                                <li style="margin-top:10px">业务范围涵盖了"软件系统研发、物联⽹应⽤研发、智慧平台解决⽅案、基础应⽤型APP⼩程序、⽹站开发、VR/AR 、游戏开发"等。</li>
                            </ul>
                            
                            <a href="/about" class="btn btn-lg btn-primary btn-round" style="font-size:18px;margin-top:10px;border-radius: 25px;padding:10px 20px;background: #222;border:none">更多信息</a>
                        </p>
                        <div>
                        </div>
                    </div>
                    <div class="col-lg-4 order-lg-1">
                        <div class="card mb-0 mr-lg-5">
                            <div class="card-body p-2">
                                <img alt="Image placeholder" src="/images/building.jpg" class="img-fluid shadow rounded">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
           
        </div>
    </section>

    <section class="slice slice-lg bg-section-dark pt-5 pt-lg-8" style="margin-top:30px">
     
        <!-- Container -->
        <div class="container position-relative zindex-100">
            <div class="col">
                <div class="row justify-content-center">
                    <div class="col-md-10 text-center">
                        <div class="mt-4 mb-6" style="color:#333">
                            <h2>
                                合作流程
                            </h2>
                          
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <section class="slice pt-0" style="margin:30px 0px" id="operation">
        <div class="container position-relative zindex-100">
            <div class="row">
                <div class="col-xl-3 col-sm-6 mt-n7">
                    <div class="card">
                        <div class="p-4">
                            <div>
                                <span class="badge badge-primary">01</span> 
                            </div>
                            <div class="mt-3">
                                <h5 class="lh-130">需求沟通</h5>
                                <p class="text-muted mb-0" style="font-size:15px">
                                    <span>需求分析 功能沟通</span><br>
                                    <span>需求评估</span>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3 col-sm-6 mt-n7">
                    <div class="card">
                        <div class="p-4">
                            <div>
                                <span class="badge badge-primary">02</span> 
                            </div>
                            <div class="mt-3">
                                <h5 class="lh-130">达成合作</h5>
                                <p class="text-muted mb-0" style="font-size:15px">
                                    <span>项目调研 给出报价</span><br>
                                    <span>成立项目</span>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3 col-sm-6 mt-n7">
                    <div class="card">
                        <div class="p-4">
                            <div>
                                <span class="badge badge-primary">03</span> 
                            </div>
                            <div class="mt-3">
                                <h5 class="lh-130">原型交互</h5>
                                <p class="text-muted mb-0" style="font-size:15px">
                                    <span>原型规划 交互设计</span><br>
                                    <span>设计确认</span>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                
                <div class="col-xl-3 col-sm-6 mt-n7">
                    <div class="card">
                        <div class="p-4">
                            <div>
                                <span class="badge badge-primary">04</span> 
                            </div>
                            <div class="mt-3">
                                <h5 class="lh-130">程序开发</h5>
                                <p class="text-muted mb-0" style="font-size:15px">
                                    <span>架构设计 数据设计</span><br>
                                    <span>程序开发</span>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-xl-3 col-sm-6">
                    <div class="card">
                        <div class="p-4">
                            <div>
                                <span class="badge badge-primary">05</span> 
                            </div>
                            <div class="mt-3">
                                <h5 class="lh-130">产品测试</h5>
                                <p class="text-muted mb-0" style="font-size:15px">
                                    <span>开发文档 功能测试</span><br>
                                    <span>性能测试</span>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3 col-sm-6">
                    <div class="card">
                        <div class="p-4">
                            <div>
                                <span class="badge badge-primary">06</span> 
                            </div>
                            <div class="mt-3">
                                <h5 class="lh-130">审核验收</h5>
                                <p class="text-muted mb-0" style="font-size:15px">
                                    <span>功能验收 程序验收</span><br>
                                    <span>细节验收</span>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3 col-md-12 col-sm-6">
                    <div class="card">
                        <div class="p-4">
                            <div>
                                <span class="badge badge-primary">07</span> 
                            </div>
                            <div class="mt-3">
                                <h5 class="lh-130">产品上线</h5>
                                <p class="text-muted mb-0" style="font-size:15px">
                                    <span>服务部署 产品发布</span><br>
                                    <span>运行配置</span>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3 col-md-12 col-sm-6">
                    <div class="card">
                        <div class="p-4">
                            <div>
                                <span class="badge badge-primary">08</span> 
                            </div>
                            <div class="mt-3">
                                <h5 class="lh-130">运营维护</h5>
                                <p class="text-muted mb-0" style="font-size:15px">
                                    <span>运行情况监控</span><br>
                                    <span>紧急服务</span>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

   
  </div>
</template>

<script>
import navbar from '../components/navbar.vue';
export default {
  name: 'HomeView',
  components: {
    navbar
  }
}
</script>

<style lang="scss">
.mask {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 508px;
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
}
.alpha-7, .alpha-container:hover .alpha-7--hover {
    opacity: 0.7;
}
.bg-primary {
    background: linear-gradient(#2c2c2c,  #0d0d0d) !important;
    fill:#0d0d0d !important;
}

.btn-outline-white{
color: #fff;
    background-color: transparent;
    background-image: none;
    border-color: #fff;
    border-radius:25px;
    font-size:17px;

    &:hover{
        color:#0d0d0d;
        background:white;
    }
}

.btn-outline-blue{
color: #fff;
    background-color: white;
    background-image: none;
    border-color: #fff;
    border-radius:25px;
   
    font-size:17px;

    &:hover{
       
        background:white;
    }
}

.card{
  margin-bottom:30px;
  box-shadow:0px 0px 15px #f5f5f5;
  border-color:#f1f1f1;
}

.lh-130{
  color:#333;
  font-weight:bold;
}

#operation{
    .badge{
        font-size:22px;
    }
}

.badge-primary{
    font-size:18px !important;
    background-color: #bea474 !important;
}
</style>
